import * as React from 'react';

// material-ui
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Typography,
    Link
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

// project imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import MainCard from "../../../ui-components/cards/maincard";

// assets
import { ApiStatus } from '../../../types';
import { deleteHelp, fetchHelp } from '../../../store/slices/help';
import { Help } from '../../../types/help';
import AddHelp from './components/addhelp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight:'600',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 10
    },
  }));

// table columns
interface ColumnProps {
    id: string;
    label: string;
    minWidth: number;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
    format?: (value: Date | number) => string | boolean;
}

const columns: ColumnProps[] = [
    { id: 'id', label: 'Sr.No.', minWidth: 50, align: 'center' },
    { id: 'title', label: 'Title', minWidth: 100, align: 'left' },
    { id: 'url', label: 'Link', minWidth: 100, align: 'left' },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center'}
];

// ==============================|| TABLE - ENHANCED ||============================== //

export default function HelpPage() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch();
    const { error, help, status } = useSelector((store) => store.help);
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    const [selectedHelp, setSelectedHelp] = useState<Help>();

    useEffect(() => {
        if (help.length <= 0) {
            dispatch(fetchHelp());
        }
    }, []);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setPage(0);
    };

    const editHelp = (h: Help) => {
        setSelectedHelp(h);
        setIsOpen(true);
    }

    const deleteHelpHere = (h: Help) => {
        dispatch(deleteHelp({id: h.id}));
    }

    return (
        <>
            <Box sx={{ margin: 2 }}>
                <MainCard
                    title="Help"
                    loading={status === ApiStatus.pending}
                    error={error}
                    secondary={
                        <Button variant="contained" onClick={() => {setIsOpen(true)}}>Add Help</Button>
                    }
                >
                    {/* table */}
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            sx={{ py: 3 }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {help.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                        >
                                            <TableCell align="center">
                                                {index+1}
                                            </TableCell>
                                            <TableCell id="message-id" align="left">
                                                {row.title}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Link href={row.url} target="_blank">
                                                    <Typography>Link</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" onClick={() => {editHelp(row)}}>Edit</Button>
                                                <Button variant="contained" color='error' onClick={() => {deleteHelpHere(row)}}>Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* table pagination */}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={Number(help.length)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </Box>
        <AddHelp help={selectedHelp} isOpen={isOpen} handleClose={()=>{setIsOpen(false); setSelectedHelp(undefined)}} />
        </>
    );
}
