export const DASHBOARD_PATH = '/dashboard';
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const fontFamily = `'Roboto', sans-serif`;
export const borderRadius = 8;
export const outlinedFilled = true;
export const navType = 'light';
export const presetColor = 'default'; // default, theme1, theme2, theme3, theme4, theme5, theme
export const locale = 'en'; // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chines
export const rtlLayout = false;
export const container = false;
