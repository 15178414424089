import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from '@mui/material';

import { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { ToolkitCategory, ToolkitSubCategory } from '../../../../types/toolkit';
import { addSubCategory, editSubCategory } from '../../../../store/slices/toolkit';
import { LoadingButton } from '@mui/lab';
import { ApiStatus } from '../../../../types';
import { dispatch, useSelector } from '../../../../store';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //
interface AddSubCategoryProps {
    isShown: boolean;
    isEditing: boolean;
    handleClose: Function;
    category: ToolkitCategory | null | undefined;
    subCategory: ToolkitSubCategory | null | undefined;
}

export default function AddSubCategory({ isShown, isEditing, handleClose, category, subCategory }: AddSubCategoryProps) {
    const theme = useTheme();
    const { status } = useSelector((state) => state.toolkit);
    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: subCategory?.name ?? '',
            categoryId: category?.id,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Sub category name is required.'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEditing) {
                    await dispatch(editSubCategory({
                        categoryId: subCategory!.id,
                        payload: {
                            name: values.name,
                        }
                    }));
                } else {
                    await dispatch(addSubCategory({
                        categoryId: category?.id,
                        name: values.name
                    }));
                    setLoading(false);
                    handleClose();
                }
            } catch (error) {
                console.log(error)
            }
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

    return (

        <>
            <DialogTitle>{isEditing ? 'Edit Sub Category' : 'Add Sub Category'}</DialogTitle>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent sx={{ width: 600 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    label={isEditing ? 'Edit Sub Category' : 'Add Sub Category'}
                                    multiline
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            onClick={() => {
                                handleClose();
                            }}
                            variant="outlined"
                            size="small"
                            sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <LoadingButton loading={status === ApiStatus.pending || loading} disabled={isSubmitting} type="submit" variant="outlined" size="small" autoFocus>
                            {isEditing ? 'Save' : 'Add SubCategory'}
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </>
    );
}
