import * as React from 'react';

import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../../store"
import { fetchMoods } from "../../../store/slices/moods";
import { ApiStatus } from "../../../types";
import MainCard from "../../../ui-components/cards/maincard";
import AddMood from "./components/add-mood";
import { Mood } from '../../../types/mood';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight:'600',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 10
    },
  }));

  // table columns
interface ColumnProps {
    id: string;
    label: string;
    minWidth: number;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
    format?: (value: Date | number) => string | boolean;
}

const columns: ColumnProps[] = [
    { id: 'index', label: 'Sr.No.', minWidth: 50, align: 'center' },
    { id: 'mood', label: 'Moods', minWidth: 100, align: 'left' },
    { id: 'message', label: 'Message', minWidth: 100, align: 'left' },
    { id: 'text', label: 'Text', minWidth: 100, align: 'left' },
    { id: 'rating', label: 'Rating', minWidth: 100, align: 'center' },
    { id: 'count', label: 'User Count', minWidth: 100, align: 'center' },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' }
];

export default function Moods() {
    const { status, error, moods } = useSelector((state) => state.moods);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [mood, setMood] = useState<Mood | undefined>(undefined);

    useEffect(() => {
        if (moods.length <= 0) {
            dispatch(fetchMoods());
        }
    }, [moods]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setPage(0);
    };
    const editMood = (mood: Mood) => {
        setMood(mood);
        setIsOpen(true);
    }
    return (
        <>
            <Box sx={{ margin: 2 }}>
                <MainCard
                    title="Moods"
                    error={error}
                    loading={status === ApiStatus.pending}
                    secondary={
                        <Button variant="contained" onClick={() => {setIsOpen(true)}}>Add Mood</Button>
                    }
                >
                    {/* table */}
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            sx={{ py: 3 }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, verticalAlign: 'top' }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {moods?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                        >
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>
                                                {index+1}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>{row.mood}</TableCell>
                                            <TableCell id="message" style={{ verticalAlign: 'top' }}>
                                                {row.message}
                                            </TableCell>
                                            <TableCell id="text" style={{ verticalAlign: 'top' }}>
                                                {row.text}
                                            </TableCell>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>{row.rating}</TableCell>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>{row.users?.length ?? 0}</TableCell>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>
                                                <Button onClick={()=>{editMood(row)}}>Edit</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                    {/* table pagination */}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={Number(moods?.length)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </MainCard>
            </Box>
            <AddMood mood={mood} isOpen={isOpen} handleClose={()=>{setIsOpen(false); setMood(undefined)}} />
        </>
    )
}
