import MainLayout from '../layouts/mainlayout';
import DailyQuestions from '../views/pages/dailyquestions';
import Dashboard from '../views/pages/dashboard';
import Mantras from '../views/pages/mantras';
import Moods from '../views/pages/moods';
import Users from '../views/pages/users';
import Toolkit from '../views/pages/toolkit';

import AuthGuard from './auth-guard';
import HelpPage from '../views/pages/help';
import SurveyList from '../views/pages/survey';
import CategoryReport from '../views/pages/reports/category';
import SubCategoryReport from '../views/pages/reports/subcategory';
import ContentReports from '../views/pages/reports/contents';
import MoodsReport from '../views/pages/reports/moods';
import MantrasReport from '../views/pages/reports/mantras';
import SurveyDetails from '../views/pages/survey/details';

export const authRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            index: true,
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/dailyquestions',
            element: <DailyQuestions />
        },
        {
            path: '/moods',
            element: <Moods />
        },
        {
            path: '/mantras',
            element: <Mantras />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/toolkit',
            element: <Toolkit />
        },
        {
            path: '/help',
            element: <HelpPage />
        },
        {
            path: '*',
            element: <Dashboard />
        },
        {
            path: '/survey',
            element: <SurveyList/>
        },
        {
            path: '/categoryreport',
            element: <CategoryReport/>
        },
        {
            path: '/subcategoryreport',
            element: <SubCategoryReport/>
        },
        {
            path: '/contentsreport',
            element: <ContentReports/>
        },
        {
            path: 'moodsreport',
            element: <MoodsReport/>
        },
        {
            path: '/mantrasreport',
            element: <MantrasReport/>
        },
        {
            path: '/survey/:id',
            element: <SurveyDetails/>
        }
    ]
};
