import MinimalLayout from '../layouts/minimal';
import { LoginPage } from '../views/pages/login';
import NavMotion from '../layouts/navmotion';
import GuestGuard from '../layouts/guestguard';

export const unAuthRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            index: true,
            element: <LoginPage />
        },
        {
            path: '/login',
            element: <LoginPage />
        }
    ]
};
