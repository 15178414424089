import { Box, Drawer, Typography, useMediaQuery, useTheme } from "@mui/material";
import { drawerWidth } from "../../../config";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { memo, useMemo } from "react";
import NavItem from "./nav-item";
import { IconBrandChrome, IconMagnet, IconMoodCrazyHappy, IconQuestionCircle, IconSettings, IconUserCircle } from '@tabler/icons';

interface SidebarProps {
    window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const drawerOpen = true;
    const container = window !== undefined ? () => window.document.body : undefined;

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                <>
                    <NavItem key='menu-4' item={{
                        id: 'users',
                        title: "All Users",
                        type: 'item',
                        url: '/users',
                        icon: IconUserCircle,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-1' item={{
                        id: 'daily-questions',
                        title: "Daily Questions",
                        type: 'item',
                        url: '/dailyquestions',
                        icon: IconQuestionCircle,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-2' item={{
                        id: 'moods',
                        title: "Moods",
                        type: 'item',
                        url: '/moods',
                        icon: IconMoodCrazyHappy,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-3' item={{
                        id: 'mantras',
                        title: "Mantras",
                        type: 'item',
                        url: '/mantras',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-5' item={{
                        id: 'toolkit',
                        title: "Toolkit",
                        type: 'item',
                        url: '/toolkit',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-6' item={{
                        id: 'help',
                        title: "Help",
                        type: 'item',
                        url: '/help',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-7' item={{
                        id: 'survey',
                        title: "Survey",
                        type: 'item',
                        url: '/survey',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-8' item={{
                        id: 'category',
                        title: "Category Report",
                        type: 'item',
                        url: '/categoryreport',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-9' item={{
                        id: 'subcategory',
                        title: "Sub Category Report",
                        type: 'item',
                        url: '/subcategoryreport',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-10' item={{
                        id: 'contentsreport',
                        title: "Contents Report",
                        type: 'item',
                        url: '/contentsreport',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-11' item={{
                        id: 'moodsreport',
                        title: "Moods Report",
                        type: 'item',
                        url: '/moodsreport',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                    <NavItem key='menu-12' item={{
                        id: 'mantrasreport',
                        title: "Mantra Report",
                        type: 'item',
                        url: '/mantrasreport',
                        icon: IconMagnet,
                        breadcrumbs: false
                    }} level={1} />
                </>
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => {}}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && drawer}
            </Drawer>
        </Box>
    );
}
export default memo(Sidebar);
