import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Theme, styled } from '@mui/material/styles';
import { dispatch, useSelector } from "../../../../store";

import MainCard from "../../../../ui-components/cards/maincard";
import { ApiStatus } from "../../../../types";
import { fetchCategoryReport, fetchSubCategoryReport } from "../../../../store/slices/reports";


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',

    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
      borderTop: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  }));

  const columns: GridColDef[] = [
    { field: 'serialNumber', headerName: 'S.No.', type: 'number', width: 100 },
    { field: 'group', headerName: 'Group', type: 'string', flex: 1 },
    { field: 'category', headerName: 'Category', type: 'string', flex: 1 },
    { field: 'subcategory', headerName: 'Subcategory', type: 'string', flex: 1 },
    { field: 'cumulativeView', headerName: 'Cumilative Views', type: 'number', width: 120, align: 'center' },
    { field: 'currentView', headerName: 'Views', type: 'number', width: 80, align: 'center' },
  ];

const SubCategoryReport = () => {
  const { subCategoryReports, status } = useSelector((state) => state.reports);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    console.log({subCategoryReports});
      if (subCategoryReports.length <= 0) {
          dispatch(fetchSubCategoryReport());
      }
  }, [subCategoryReports]);

  return (
      <Box sx={{ margin: 2 }}>
          <MainCard loading={status === ApiStatus.pending} title="Sub Category Views">
              <Box sx={{ height: 800, width: '100%', backgroundColor: 'none' }}>
                  <StyledDataGrid
                      aria-label="Category Views"
                      rows={subCategoryReports}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      disableSelectionOnClick
                      components={{ Toolbar: GridToolbar }}
                      getRowId={(row) => row.serialNumber}
                  />
              </Box>
          </MainCard>
      </Box>
  )

}
export default SubCategoryReport;
