import {
    Box,
    Select,
    MenuItem,
    Button,
    Card,
    Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "../../../store";
import { useEffect, useState } from "react";
import { deleteContent, fetchGroups } from "../../../store/slices/toolkit";
import MainCard from "../../../ui-components/cards/maincard";
import {
    ToolkitCategory,
    ToolkitContent,
    ToolkitGroup,
    ToolkitSubCategory,
} from "../../../types/toolkit";
import AddCategory from "./components/add-category";
import AddSubCategory from "./components/add-subcategory";
import AddContent from "./components/add-content";
import ContentList from "./components/content-list";

// table columns


const Toolkit = () => {
    const dispatch = useDispatch();
    const { groups } = useSelector((state) => state.toolkit);
    const [selectedGroup, setSelectedGroup] = useState<ToolkitGroup | null | undefined>(null);
    const [selectedCategory, setSelectedCategory] = useState<ToolkitCategory | null | undefined>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<ToolkitSubCategory | null | undefined>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [isOpenContent, setIsOpenContent] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectedContent, setSelectedContent] = useState<ToolkitContent | undefined>(undefined);

    useEffect(() => {
        if (groups.length <= 0) {
            dispatch(fetchGroups());
        } else {
            // groups loaded
            setSelectedGroup(groups.find((g) => g.id === selectedGroup?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups]);

    useEffect(() => {
        if (selectedGroup) {
            setSelectedCategory(
                selectedGroup.categories.find((e) => e.id === selectedCategory?.id)
            );
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (selectedSubCategory) {
            setSelectedSubCategory(
                selectedCategory?.subCategories.find(
                    (e) => e.id === selectedSubCategory?.id
                )
            );
        }
    }, [selectedCategory]);

    const handleGroupChange = (event: any) => {
        const selectedGroupId = event.target.value as number;
        const selectedGroup = groups.find(
            (group: ToolkitGroup) => group.id === selectedGroupId
        );
        setSelectedGroup(selectedGroup || null);
        setSelectedCategory(null);
        setSelectedSubCategory(null);
    };

    const handleCategoryChange = (event: any) => {
        const selectedCategoryId = event.target.value as number;
        const selectedCategory =
            selectedGroup?.categories.find(
                (category) => category.id === selectedCategoryId
            ) || null;
        setSelectedCategory(selectedCategory);
        setSelectedSubCategory(null);
    };

    const handleSubCategoryChange = (event: any) => {
        const selectedCategoryId = event.target.value as number;
        const selected =
            selectedCategory?.subCategories.find(
                (category) => category.id === selectedCategoryId
            ) || null;
        setSelectedSubCategory(selected);
    };


    const editContent = (content: ToolkitContent) => {
        setSelectedContent(content);
        setIsOpenContent(true);
        setIsEditing(true);
    }

    const deleteContentLocal = (content: ToolkitContent) => {
        dispatch(deleteContent({id: content.id}));
    }
    return (
        <>
            <Box style={{ margin: 2 }}>
                <MainCard title="Toolkit">
                    <Box style={{ display: "flex", alignItems: "center" }} sx={{ m: 1 }}>
                        <div style={{ width: "13%" }}>
                            <label htmlFor="group-select" style={{ paddingRight: 1 }}>
                                Select a group:
                            </label>
                        </div>
                        <Select
                            value={selectedGroup?.id || ""}
                            SelectDisplayProps={{
                                style: {
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    marginLeft: 2,
                                    marginRight: 2,
                                },
                            }}
                            onChange={handleGroupChange}
                            id="group-select"
                            autoWidth={false}
                            style={{ width: "20%" }}
                            sx={{ m: 1 }}
                        >
                            <MenuItem value="">Select a group</MenuItem>
                            {groups.map((group: ToolkitGroup) => (
                                <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    {selectedGroup && (
                        <Box
                            style={{ display: "flex", alignItems: "center" }}
                            sx={{ m: 1 }}
                        >
                            <div style={{ width: "13%" }}>
                                <label htmlFor="category-select" style={{ paddingRight: 1 }}>
                                    Select a category:
                                </label>
                            </div>
                            <Select
                                value={selectedCategory?.id || ""}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        marginLeft: 2,
                                        marginRight: 2,
                                    },
                                }}
                                onChange={handleCategoryChange}
                                id="category-select"
                                style={{ width: "20%" }}
                                sx={{ m: 1 }}
                            >
                                <MenuItem value="">Select a category</MenuItem>
                                {selectedGroup.categories.map((category: ToolkitCategory) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Button
                                variant="contained"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                    setIsEditing(false);
                                    setIsOpen(true);
                                }}
                            >
                                Add Category
                            </Button>
                            <Button
                                disabled={!selectedCategory}
                                variant="contained"
                                onClick={() => {
                                    setIsEditing(true);
                                    setIsOpen(true);
                                }}
                            >
                                Edit Category
                            </Button>
                        </Box>
                    )}
                    {selectedGroup && selectedCategory && (
                        <>
                            <Box
                                style={{ display: "flex", alignItems: "center" }}
                                sx={{ m: 1 }}
                            >
                                <div style={{ width: "13%" }}>
                                    <label
                                        htmlFor="sub-category-select"
                                        style={{ paddingRight: 1 }}
                                    >
                                        Select a sub category:
                                    </label>
                                </div>
                                <Select
                                    value={selectedSubCategory?.id || ""}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            marginLeft: 2,
                                            marginRight: 2,
                                        },
                                    }}
                                    onChange={handleSubCategoryChange}
                                    id="sub-category-select"
                                    style={{ width: "20%" }}
                                    sx={{ m: 1 }}
                                >
                                    <MenuItem value="">Select a sub category</MenuItem>
                                    {selectedCategory.subCategories.map(
                                        (subcategory: ToolkitSubCategory) => (
                                            <MenuItem key={subcategory.id} value={subcategory.id}>
                                                {subcategory.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <Button
                                    variant="contained"
                                    sx={{ mr: 1 }}
                                    onClick={() => {
                                        setIsEditing(false);
                                        setIsShown(true);
                                    }}
                                >
                                    Add Sub Category
                                </Button>
                                <Button
                                    disabled={!selectedSubCategory}
                                    variant="contained"
                                    onClick={() => {
                                        setIsEditing(true);
                                        setIsShown(true);
                                    }}
                                >
                                    Edit Sub Category
                                </Button>
                            </Box>
                        </>
                    )}
                    {selectedSubCategory && (
                        <>
                            <Card style={{ display: "flex", alignItems: "right" }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setIsOpenContent(true);
                                        setIsEditing(false);
                                    }}
                                >
                                    Add Content
                                </Button>
                            </Card>
                            <ContentList contents={selectedSubCategory.contents} editContent={editContent} deleteContent={deleteContentLocal} />
                        </>
                    )}
                </MainCard>
            </Box>
            <Dialog
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                sx={{ p: 3 }}
            >
                <AddCategory
                    isOpen={isOpen}
                    handleClose={() => {
                        setIsEditing(false);
                        setIsOpen(false);
                    }}
                    group={selectedGroup}
                    isEditing={isEditing}
                    category={selectedCategory}
                />
            </Dialog>

            <Dialog
                open={isShown}
                onClose={() => {
                    setIsShown(false);
                }}
                sx={{ p: 3 }}
            >
                <AddSubCategory
                    isShown={isShown}
                    handleClose={() => {
                        setIsEditing(false);
                        setIsShown(false);
                    }}
                    isEditing={isEditing}
                    category={selectedCategory}
                    subCategory={selectedSubCategory}
                />
            </Dialog>
            <Dialog
                open={isOpenContent}
                onClose={() => {
                    setIsOpenContent(false);
                    setSelectedContent(undefined);
                }}
                sx={{ p: 3 }}
            >
                <AddContent
                    content={selectedContent}
                    isOpenContent={isOpenContent}
                    handleClose={() => {
                        setIsOpenContent(false);
                        setSelectedContent(undefined);
                    }}
                    isEditing={isEditing}
                    subCategory={selectedSubCategory}
                />
            </Dialog>
        </>
    );
};

export default Toolkit;
