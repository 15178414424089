import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../../store"
import { fetchUsers } from "../../../store/slices/user";
import { Theme, styled } from '@mui/material/styles';
import MainCard from "../../../ui-components/cards/maincard";

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',

    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
      borderTop: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  }));

  const columns: GridColDef[] = [
    { field: 'id', type: 'number', renderHeader: () => (<strong>{'Sr.No.'}</strong>), renderCell: (index) => index.api.getRowIndex(index.row.id) + 1, width: 80, },
    { field: 'name', renderHeader: () => (<strong>{'Fullname'}</strong>), editable: true, type: 'string', flex: 1 },
    { field: 'email', renderHeader: () => (<strong>{'Email'}</strong>), editable: true, type: 'string', flex: 1 },
    { field: 'ethnicity', renderHeader: () => (<strong>{'Ethnicity'}</strong>), editable: false, type: 'string', width: 80 },
    { field: 'phone', renderHeader: () => (<strong>{'Phone'}</strong>), editable: false, type: 'string', width: 80 },
    { field: 'jobTitle', renderHeader: () => (<strong>{'Job Title'}</strong>), editable: false, type: 'string', width: 80 },
    { field: 'gender', renderHeader: () => (<strong>{'Gender'}</strong>), editable: false, type: 'string', width: 80 },
    {
      field: 'likedMantras',
      renderHeader: () => (<strong>{'Liked Mantras'}</strong>),
      valueGetter(params) {
        return params.row.likedMantras.length;
      },
      editable: false,
      type: 'string',
      width: 120,
      align: 'center'
    },
    {
      field: 'selectedMoods',
      renderHeader: () => (<strong>{'Moods'}</strong>),
      valueGetter(params) {
        return params.row.selectedMoods.length;
      },
      editable: false,
      type: 'string',
      width: 120,
      align: 'center'
    },
    {
      field: 'ContentLike',
      renderHeader: () => (<strong>{'Liked Contents'}</strong>),
      valueGetter(params) {
        return params.row.ContentLike.length;
      },
      editable: false,
      type: 'string',
      width: 120,
      align: 'center'
    },
    {
      field: 'userNotes',
      renderHeader: () => (<strong>{'Notes'}</strong>),
      valueGetter(params) {
        return params.row.userNotes.length;
      },
      editable: false,
      type: 'string',
      width: 120,
      align: 'center'
    },
    { field: 'struggle', renderHeader: () => (<strong>{'Struggle'}</strong>), editable: false, type: 'string', valueGetter: ({ value }) => value?.struggle ?? "", width: 120, align: 'center' },
    { field: 'dob', renderHeader: () => (<strong>{'Date of Birth'}</strong>), editable: false, type: 'date', valueGetter: ({ value }) => value && new Date(value), width: 120, align: 'center' }
];

const Users = () => {
    const {users} = useSelector((state) => state.users);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        if (users.length <= 0) {
            dispatch(fetchUsers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    return (
        <Box sx={{ margin: 2 }}>
            <MainCard title="Users">
                <Box sx={{ height: 800, width: '100%', backgroundColor: 'none' }}>
                    <StyledDataGrid
                        aria-label="Users"
                        rows={users}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20]}
                        pagination
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar }}
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                </Box>
            </MainCard>
        </Box>
    )
}
export default Users;
