import React, { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Switch
} from '@mui/material';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { dispatch, useSelector } from '../../../../store';
import { addDailyQuestion, updateDailyQuestion } from '../../../../store/slices/daily-questions';
import { LoadingButton } from '@mui/lab';
import { ApiStatus } from '../../../../types';
import { useState } from 'react';
import { DailyQuestion } from '../../../../types/daily-question';


// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //
interface AddDailyQuestionProps {
    isOpen: boolean;
    handleClose: Function;
    dailyQuestion?: DailyQuestion
}

export default function AddDailyQuestion({ isOpen, handleClose, dailyQuestion }: AddDailyQuestionProps) {
    const theme = useTheme();
    const {questions, error, status } = useSelector((state) => state.dailyQuestions);
    const [loading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState(dailyQuestion?.isActive);

    useEffect(() => {
    }, [questions]);

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            question: dailyQuestion?.question ?? '',
            isActive: dailyQuestion?.isActive ?? '',
        },
        validationSchema: dailyQuestion ? Yup.object().shape({
            question: Yup.string().required('Question is required'),
        }) : Yup.object().shape({
            question: Yup.string().required('Question is required'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (dailyQuestion) {
                    setLoading(true);
                    if (!values.question) {
                        setLoading(false);
                        setSubmitting(false);
                        return;
                    }
                    await dispatch(updateDailyQuestion({
                        id: dailyQuestion?.id,
                        payload: {
                            question: values.question,
                            isActive: checked ? "true" : "false"
                        }
                    }));
                    setLoading(false);
                } else {
                    setLoading(true);
                    await dispatch(addDailyQuestion(values));
                    setLoading(false);
                }
            handleClose();
        } catch (error) {
                console.log(error)
            }
        }

    });

    const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }
    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                handleClose();
            }}
            sx={{ p: 3 }}
        >
            <>
                <DialogTitle>{ dailyQuestion ? 'Update' : 'Add'} Question</DialogTitle>
                {error && (
                    <Alert variant="outlined" color="error">
                        error
                    </Alert>
                )}
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogContent sx={{width: 600}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                 <TextField
                                        fullWidth
                                        label="Question"
                                        multiline
                                        {...getFieldProps('question')}
                                        error={Boolean(touched.question && errors.question)}
                                        helperText={touched.question && errors.question}
                                    />
                                    { dailyQuestion ? <Switch onChange={handleChange} defaultChecked={dailyQuestion?.isActive} name="statusActive" /> : ''}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                onClick={() => {
                                    handleClose();
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <LoadingButton loading={status === ApiStatus.pending || loading} disabled={isSubmitting} type="submit" variant="outlined" size="small" autoFocus>
                                {dailyQuestion ? 'Save' : 'Create'}
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </>
        </Dialog>
    );
}
