// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps } from '../../types';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['help'] = {
    error: null,
    status: ApiStatus.idle,
    help: []
};

export const fetchHelp = createAsyncThunk('/help', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/help`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addHelp = createAsyncThunk('/help/create', async (data: any, thunkAPI) => {
    try {
        const response = await axios.post(`/help/create`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateHelp = createAsyncThunk('/help/update', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/help/${data.id}`, {
            title: data.title,
            url: data.url,
        });
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const deleteHelp = createAsyncThunk('/help/delete', async (data: any, thunkAPI) => {
    try {
        const response = await axios.delete(`/help/${data.id}`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});


const slice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.help = initialState.help;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchHelp.fulfilled, (state, action) => ({
            help: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchHelp.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchHelp.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add mantra
        builder.addCase(addHelp.fulfilled, (state, action) => ({
            help: [ ...state.help, action.payload],
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addHelp.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addHelp.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit mantra
        builder.addCase(updateHelp.fulfilled, (state, action) => {
            const idx = state.help.findIndex((e) => { return (e.id === action.payload.id)});
            const temp: any = [...state.help];
            temp.splice(idx, 1);
            return {
                help: [ ...temp, action.payload],
                error: null,
                status: ApiStatus.success
            }
        });
        builder.addCase(updateHelp.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(updateHelp.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit mantra
        builder.addCase(deleteHelp.fulfilled, (state, action) => {
            const idx = state.help.findIndex((e) => { return (e.id === action.payload.id)});
            const temp: any = [...state.help];
            temp.splice(idx, 1);
            return {
                help: [ ...temp],
                error: null,
                status: ApiStatus.success
            }
        });
        builder.addCase(deleteHelp.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(deleteHelp.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
