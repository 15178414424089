// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps } from '../../types';
import { Mantra } from '../../types/mantra';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['mantras'] = {
    error: null,
    status: ApiStatus.idle,
    mantras: []
};

export const fetchMantras = createAsyncThunk('/mantras', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/mantras`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addMantra = createAsyncThunk('/mantras/add', async (data: any, thunkAPI) => {
    try {
        const response = await axios.post(`/mantras/add`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateMantra = createAsyncThunk('/mantras/update', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/mantras/update/${data.id}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'mantras',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.mantras = initialState.mantras;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMantras.fulfilled, (state, action) => ({
            mantras: action.payload.sort((a: Mantra, b: Mantra) => a.createdAt.getTime() - b.createdAt.getTime()),
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchMantras.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchMantras.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add mantra
        builder.addCase(addMantra.fulfilled, (state, action) => ({
            mantras: [ ...state.mantras, action.payload].sort((a: Mantra, b: Mantra) => a.createdAt.getTime() - b.createdAt.getTime()),
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addMantra.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addMantra.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit mantra
        builder.addCase(updateMantra.fulfilled, (state, action) => {
            const idx = state.mantras.findIndex((e) => { return (e.id === action.payload.id)});
            const temp: any = [...state.mantras];
            temp.splice(idx, 1);
            return {
                mantras: [ ...temp, action.payload].sort((a: Mantra, b: Mantra) => a.createdAt.getTime() - b.createdAt.getTime()),
                error: null,
                status: ApiStatus.success
            }
        });
        builder.addCase(updateMantra.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(updateMantra.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
