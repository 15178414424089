// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps } from '../../types';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['toolkit'] = {
    error: null,
    status: ApiStatus.idle,
    groups: []
};

export const fetchGroups = createAsyncThunk('/toolkitgroups', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/toolkit/admingroups`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addCategory = createAsyncThunk('/toolkit/category', async (data: any, thunkAPI) => {
    try {
        const response = await axios.put(`/toolkit/category`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const editCategory = createAsyncThunk('/toolkit/editcategory', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/toolkit/category/${data.categoryId}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addSubCategory = createAsyncThunk('/toolkit/subcategory', async (data: any, thunkAPI) => {
    try {
        const response = await axios.put(`/toolkit/subcategory`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const editSubCategory = createAsyncThunk('/toolkit/editsubcategory', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/toolkit/subcategory/${data.categoryId}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addContent = createAsyncThunk('/toolkit/content', async (data: any, thunkAPI) => {
    try {
        const response = await axios.put(`/toolkit/content`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const editContent = createAsyncThunk('/toolkit/editcontent', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/toolkit/content/${data.contentId}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const reorderContent = createAsyncThunk('/toolkit/reorder/content', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/toolkit/reorder/content`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const deleteContent = createAsyncThunk('/toolkit/content/delete', async (data: any, thunkAPI) => {
    try {
        const response = await axios.delete(`/toolkit/content/${data.id}`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const changeLiveStatus = createAsyncThunk('/toolkit/content/live', async (data: {id: number, live: boolean}, thunkAPI) => {
    try {
        const response = await axios.put(`/toolkit/content/live/${data.id}`, { live: data.live });
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const deleteCategory = createAsyncThunk('/toolkit/category/delete', async (data: any, thunkAPI) => {
    try {
        const response = await axios.delete(`/toolkit/category/${data.id}`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const deleteSubCategory = createAsyncThunk('/toolkit/subcategory/delete', async (data: any, thunkAPI) => {
    try {
        const response = await axios.delete(`/toolkit/subcategory/${data.id}`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'toolkit',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.groups = initialState.groups;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchGroups.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchGroups.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchGroups.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add category
        builder.addCase(addCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit category
        builder.addCase(editCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(editCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(editCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add subcategory
        builder.addCase(addSubCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addSubCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addSubCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit subcategory
        builder.addCase(editSubCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(editSubCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(editSubCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add Content
        builder.addCase(addContent.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addContent.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addContent.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Edit Content
        builder.addCase(editContent.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(editContent.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(editContent.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Reorder Content
        builder.addCase(reorderContent.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(reorderContent.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(reorderContent.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Delete Content
        builder.addCase(deleteContent.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(deleteContent.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(deleteContent.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        //changeLiveStatus
        builder.addCase(changeLiveStatus.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(changeLiveStatus.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(changeLiveStatus.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        //deleteCategory
        builder.addCase(deleteCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(deleteCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(deleteCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        //deleteSubCategory
        builder.addCase(deleteSubCategory.fulfilled, (state, action) => ({
            groups: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(deleteSubCategory.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(deleteSubCategory.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
