import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { ToolkitContent } from "../../../../types/toolkit";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableTableRow from "./content-list-row";
import { reorderContent } from "../../../../store/slices/toolkit";
import { useDispatch } from "../../../../store";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 10,
  },
}));

// ... ColumnProps, columns and ContentListProps definitions
interface ColumnProps {
  id: string;
  label: string;
  minWidth: number;
  align?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
  format?: (value: Date | number) => string | boolean;
}

const columns: ColumnProps[] = [
  { id: "index", label: "Sr.No.", minWidth: 50, align: "center" },
  { id: "isLive", label: "Is Live", minWidth: 50, align: "center" },
  { id: "title", label: "Title", minWidth: 100, align: "left" },
  { id: "description", label: "Description", minWidth: 100, align: "left" },
  { id: "views", label: "Views", minWidth: 100, align: "center" },
  { id: "likes", label: "Likes", minWidth: 100, align: "center" },
  { id: "video", label: "Video", minWidth: 100, align: "center" },
  { id: "actions", label: "Actions", minWidth: 100, align: "center" },
];

interface ContentListProps {
  contents: ToolkitContent[];
  editContent: (content: ToolkitContent) => void;
  deleteContent: (content: ToolkitContent) => void;
}

export default function ContentList({
  contents,
  editContent,
  deleteContent
}: ContentListProps) {
  const [rows, setRows] = useState<ToolkitContent[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setRows(contents);
  }, [contents]);

  // ... getLink function definition
  const getLink = (url: string) => {
    return (
      <Link href={url} target="_blank">
        <Typography>Link</Typography>
      </Link>
    );
  };
  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            const newRows = [...rows];
            const [removed] = newRows.splice(result.source.index, 1);
            newRows.splice(result.destination.index, 0, removed);

            setRows(newRows);
            dispatch(reorderContent({
              order: newRows.map((e)=>e.id)
            }));
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      sx={{ py: 3 }}
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        verticalAlign: "top",
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <Droppable droppableId="contents">
                {(provided: any) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {rows.map((content, index) => (
                      <DraggableTableRow
                        content={content}
                        editContent={editContent}
                        deleteContent={deleteContent}
                        index={index}
                        getLink={getLink}
                      />
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </Box>
    </div>
  );
}
