export enum Gender {
    FEMALE,
    MALE,
    OTHER,
    PREFER_NOT_TO_ANSWER,
}

export interface User {
    name: string;
    gender: Gender;
    dob: string;
    isAdmin: boolean;

    createdAt: Date
}

export interface SurveyUser {
  id: number;
  email: string;
  name: string;
  phone: string;
  jobTitle: string;
  lifelineNumber: string;
  relationship: string;
  gender: string;
  ethnicity: string;
  dob: string;
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;
  struggleId: number;
}
