import { JWTProvider as AuthProvider } from './contexts/JWTContext';
// routing
import Routes from './routes';
import ThemeCustomization from './themes';

function App() {
  return (
    <ThemeCustomization>
      <AuthProvider>
        <Routes/>
      </AuthProvider>
    </ThemeCustomization>
  );
}

export default App;
