import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { dispatch, useSelector } from "../../../../store";
import S3Utils from "../../../../utils/s3";
import { Help } from "../../../../types/help";
import { LoadingButton } from "@mui/lab";
import { ApiStatus } from "../../../../types";
import { useState } from "react";
import { addHelp, updateHelp } from "../../../../store/slices/help";

interface AddHelpProps {
  isOpen: boolean;
  handleClose: Function;
  help?: Help;
}

export default function AddHelp({ isOpen, handleClose, help }: AddHelpProps) {
  const theme = useTheme();
  const { error, status } = useSelector((state) => state.help);

  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: help?.title ?? "",
      mediaFile: undefined,
    },
    validationSchema: help ? Yup.object().shape({
        title: Yup.string().required('Title is required'),
        mediaFile: Yup.mixed().optional(),
    }) : Yup.object().shape({
        title: Yup.string().required('Title is required'),
        mediaFile: Yup.mixed().required("Media file is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
      try {
        setLoading(true);
        if (help) {
          console.log(values.mediaFile);
          if (values.mediaFile) {
              const mediaUrl = await S3Utils.uploadFile(values.mediaFile);
              dispatch(updateHelp({
                  id: help.id,
                  title: values.title,
                  url: mediaUrl
              }));
          } else {
              dispatch(updateHelp({
                  id: help.id,
                  title: values.title,
                  url: help.url
              }));
          }
        } else {
          const mediaUrl = await S3Utils.uploadFile(values.mediaFile);
          dispatch(addHelp({
              title: values.title,
              url: mediaUrl
          }));
        }
        setLoading(false);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      sx={{ p: 3 }}
    >
      <>
        <DialogTitle>{help ? "Update" : "Add"} Help</DialogTitle>
        {error && (
          <Alert variant="outlined" color="error">
            error
          </Alert>
        )}
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ width: 600 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    {...getFieldProps("title")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Upload File</Typography>
                  {errors.mediaFile && touched.mediaFile && (
                    <Alert color="error">{errors.mediaFile}</Alert>
                  )}
                  <input
                    id="mediaFile"
                    name="mediaFile"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("mediaFile", event.currentTarget.files?.[0]);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
              <Button
                onClick={() => {
                  handleClose();
                }}
                variant="outlined"
                size="small"
                sx={{
                  color: theme.palette.primary.dark,
                  borderColor: theme.palette.primary.dark,
                }}
                color="secondary"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={status === ApiStatus.pending || loading}
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
                size="small"
                autoFocus
              >
                {help ? "Save" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </>
    </Dialog>
  );
}
