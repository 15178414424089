// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps } from '../../types';
import { DailyQuestion } from '../../types/daily-question';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['dailyQuestions'] = {
    error: null,
    status: ApiStatus.idle,
    questions: []
};

export const fetchDailyQuestions = createAsyncThunk('/dailyquestions', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/dailyquestions`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addDailyQuestion = createAsyncThunk('/dailyquestions/add', async (data: any, thunkAPI) => {
    try {
        const response = await axios.post(`/dailyquestions/add`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateDailyQuestion = createAsyncThunk('/dailyquestions/update', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/dailyquestions/update/${data.id}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'dailyQuestions',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.questions = initialState.questions;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchDailyQuestions.fulfilled, (state, action) => ({
            questions: action.payload.sort((a: DailyQuestion, b: DailyQuestion) => a.createdAt.getTime() - b.createdAt.getTime()),
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchDailyQuestions.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchDailyQuestions.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add daily question
        builder.addCase(addDailyQuestion.fulfilled, (state, action) => ({
            questions: [ ...state.questions, action.payload],
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addDailyQuestion.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addDailyQuestion.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Update daily question
        builder.addCase(updateDailyQuestion.fulfilled, (state, action) => {
            const idx = state.questions.findIndex((e) => { return (e.id === action.payload.id)});
            const temp: any = [...state.questions];
            temp.splice(idx, 1);
            return {
                questions: [ ...temp, action.payload].sort((a: DailyQuestion, b: DailyQuestion) => a.createdAt.getTime() - b.createdAt.getTime()),
                error: null,
                status: ApiStatus.success
            }
        });
        builder.addCase(updateDailyQuestion.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(updateDailyQuestion.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
