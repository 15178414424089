// third-party
import { combineReducers } from 'redux';
import dailyQuestionsReducer from './slices/daily-questions';
import moodsReducer from './slices/moods';
import menuReducer from './slices/menu';
import mantrasReducer from './slices/mantras';
import usersReducer from './slices/user';
import toolkitReducer from './slices/toolkit';
import helpReducer from './slices/help';
import reportsReducer from './slices/reports';
import surveyReducer from './slices/survey';
// project imports
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    dailyQuestions: dailyQuestionsReducer,
    moods: moodsReducer,
    menu: menuReducer,
    mantras: mantrasReducer,
    users: usersReducer,
    toolkit: toolkitReducer,
    help: helpReducer,
    reports: reportsReducer,
    survey: surveyReducer,
});

export default reducer;
