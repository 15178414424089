// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { dispatch, useSelector } from '../../../../store';
import S3Utils from '../../../../utils/s3';
import { addMantra, updateMantra } from '../../../../store/slices/mantras';
import { Mantra } from '../../../../types/mantra';
import { LoadingButton } from '@mui/lab';
import { ApiStatus } from '../../../../types';
import { useState } from 'react';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //
interface AddMantraProps {
    isOpen: boolean;
    handleClose: Function;
    mantra?: Mantra;
}

export default function AddMantra({ isOpen, handleClose, mantra }: AddMantraProps) {
    const theme = useTheme();
    const {error, status } = useSelector((state) => state.mantras);
    const ANIMATION_SUPPORTED_FORMATS = [
        "image/gif",
        "image/png",
        "video/mp4"
    ];

    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: mantra?.message ?? '',
            relatedText: mantra?.relatedText ?? '',
            animationFile: mantra?.animatedGif ?? undefined,
            audioFile: mantra?.mp3Audio ?? undefined
        },
        validationSchema: mantra ? Yup.object().shape({
            message: Yup.string().required('Message is required'),
            relatedText: Yup.string().required('Related Text is required'),
            animationFile: Yup.mixed().optional(),
            audioFile: Yup.mixed().optional()
        }) : Yup.object().shape({
            message: Yup.string().required('Message is required'),
            relatedText: Yup.string().required('Related Text is required'),
            animationFile: Yup.mixed().required("Animation file is required").test(
                "fileFormat",
                "Unsupported Format",
                value => value && ANIMATION_SUPPORTED_FORMATS.includes(value.type)
            ),
            audioFile: Yup.mixed().required("Audio file is required")
        }),
        onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
            try {
                if (mantra) {
                    setLoading(true);
                    var animResult = undefined;
                    if (mantra.animatedGif !== values.animationFile) {
                        //upload files
                        animResult = await S3Utils.uploadFile(values.animationFile);
                        if (!animResult) {
                            setLoading(false);
                            setSubmitting(false);
                            setFieldError('animationFile', 'Error occured while uploading file');
                            return;
                        }
                    }

                    var audioResult = undefined;
                    if (mantra.mp3Audio !== values.audioFile) {
                        audioResult = await S3Utils.uploadFile(values.audioFile);
                        if (!audioResult) {
                            setLoading(false);
                            setSubmitting(false);
                            setFieldError('animationFile', 'Error occured while uploading file');
                            return;
                        }
                    }

                    await dispatch(updateMantra({
                        id: mantra.id,
                        payload: {
                            message: values.message,
                            relatedText: values.relatedText,
                            animatedGif: animResult,
                            mp3Audio: audioResult
                        }
                    }));
                    setLoading(false);
                } else {
                    setLoading(true);
                    //upload files
                    const animResult = await S3Utils.uploadFile(values.animationFile);
                    if (!animResult) {
                        setLoading(false);
                        setSubmitting(false);
                        setFieldError('animationFile', 'Error occured while uploading file');
                        return;
                    }
                    const audioResult = await S3Utils.uploadFile(values.audioFile);
                    if (!audioResult) {
                        setLoading(false);
                        setSubmitting(false);
                        setFieldError('animationFile', 'Error occured while uploading file');
                        return;
                    }
                    await dispatch(addMantra({
                        message: values.message,
                        relatedText: values.relatedText,
                        animatedGif: animResult,
                        mp3Audio: audioResult
                    }));
                    setLoading(false);
                }

                handleClose();
            } catch (error) {
                console.log(error)
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, isSubmitting } = formik;

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                handleClose();
            }}
            sx={{ p: 3 }}
        >
            <>
                <DialogTitle>{ mantra ? 'Update' : 'Add'} Mantra</DialogTitle>
                {error && (
                    <Alert variant="outlined" color="error">
                        error
                    </Alert>
                )}
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogContent sx={{width: 600}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        {...getFieldProps('message')}
                                        error={Boolean(touched.message && errors.message)}
                                        helperText={touched.message && errors.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Related Text"
                                        multiline
                                        {...getFieldProps('relatedText')}
                                        error={Boolean(touched.relatedText && errors.relatedText)}
                                        helperText={touched.relatedText && errors.relatedText}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Upload Animation</Typography>
                                    {errors.animationFile && touched.animationFile && <Alert color="error">{errors.animationFile}</Alert>}
                                    <input id="animationFile" name="animationFile" type="file" onChange={(event) => {
                                            setFieldValue("animationFile", event.currentTarget.files?.[0] );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Upload Music</Typography>
                                    {errors.audioFile && touched.audioFile && <Alert color="error">{errors.audioFile}</Alert>}
                                    <input id="audioFile" name="audioFile" type="file" onChange={(event) => {
                                            setFieldValue("audioFile", event.currentTarget.files?.[0] );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                onClick={() => {
                                    handleClose();
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <LoadingButton loading={status === ApiStatus.pending || loading} disabled={isSubmitting} type="submit" variant="outlined" size="small" autoFocus>
                                { mantra ? 'Save' : 'Create'}
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </>
        </Dialog>
    );
}
