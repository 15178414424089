// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps, SurveyUser } from '../../types';
import axios from '../../utils/axios';
import { SurveyData } from '../../types/survey';

// ----------------------------------------------------------------------
function getSurveyData(surveys: SurveyData[]): Record<number, {user: SurveyUser, surveys: SurveyData[]}> {
  return surveys.reduce<Record<number, {user: SurveyUser, surveys: SurveyData[]}>>((acc, survey) => {
      if (acc[survey.userId]) {
          acc[survey.userId].surveys.push(survey);
      } else {
          acc[survey.userId] = {user: survey.user, surveys: [survey]};
      }

      return acc;
  }, {});
}

const initialState: DefaultRootStateProps['survey'] = {
    error: null,
    status: ApiStatus.idle,
    surveyData: []
};

export const fetchSurveys = createAsyncThunk('/survey', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/survey`);
        const surveyData = getSurveyData(response.data);
        const usersArray = Object.values(surveyData);
        usersArray.sort((a, b) => {
          const aLatestSurveyTime = new Date(Math.max(...a.surveys.map(survey => new Date(survey.updatedAt).getTime())));
          const bLatestSurveyTime = new Date(Math.max(...b.surveys.map(survey => new Date(survey.updatedAt).getTime())));

          // Sort in descending order so that the latest survey entry users are on top
          return bLatestSurveyTime.getTime() - aLatestSurveyTime.getTime();
        });
        return usersArray;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.surveyData = initialState.surveyData;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchSurveys.fulfilled, (state, action) => ({
            surveyData: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchSurveys.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchSurveys.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
