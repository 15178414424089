import React, { useEffect, useState } from 'react';
import MainCard from '../../../ui-components/cards/maincard';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { dispatch, useSelector } from '../../../store';
import { SurveyData } from '../../../types/survey';
import { fetchSurveys } from '../../../store/slices/survey';
import { ApiStatus, SurveyUser } from '../../../types';


const SurveyList: React.FC = () => {
  const [rows, setRows] = useState<{user: SurveyUser, surveys: SurveyData[]}[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { error, status, surveyData } = useSelector((state) => state.survey);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSurveys());
  }, []);

  useEffect(() => {
    if (surveyData) {
      setRows(surveyData);
    }
  }, [surveyData]);

  const filteredRows = rows.filter((row) =>
    row.user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getLastSurveyDate = (surveys: SurveyData[]) => {
    if (!surveys.length) return "";
    const survey = surveys[surveys.length - 1];
    return format(survey.createdAt, "yyyy-MM-dd HH:mm");
  }

  return (
    <MainCard title="Users who have taken survey" loading={status === ApiStatus.pending} error={error}>
      <div style={{ height: '70vh', width: '100%', overflowY: "scroll" }}>
        <TextField
          label="Search by name"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <TableContainer>
          <Table aria-label="survey table">
            <TableHead>
              <TableRow>
                <TableCell>Sr No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, index) => (
                <TableRow key={row.user.id} sx={{ cursor: "pointer" }} onClick={()=>{
                  navigate(`/survey/${row.user.id}`);
                }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell>{getLastSurveyDate(row.surveys)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </MainCard>
  );
};

export default SurveyList;
