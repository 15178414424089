import axios from "axios";
import axiosServices from "./axios";

export default class S3Utils {
    public static async uploadFile(file: any) {
        const result = await axiosServices.get(`/aws/createSignedUrl?filename=${file.name}&filetype=${file.type}`);
        const uploadUrl = result.data;
        const ax = axios.create({});
        const response = await ax.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type
            },
        });
        if (response.status === 200) {
            const url = new URL(uploadUrl);
            return url.origin + url.pathname;
        } else {
            return null;
        }
    }
}
