// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps, User } from '../../types';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['users'] = {
    error: null,
    status: ApiStatus.idle,
    users: []
};

export const fetchUsers = createAsyncThunk('/users', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/users/admin`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.users = initialState.users;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchUsers.fulfilled, (state, action) => ({
            users: action.payload.sort((a: User, b: User) => a.createdAt.getTime() - b.createdAt.getTime()),
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchUsers.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
