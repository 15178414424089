import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { dispatch, useSelector } from '../../../store';
import MainCard from '../../../ui-components/cards/maincard';
import { ApiStatus, SurveyUser } from '../../../types';
import { SurveyData } from '../../../types/survey';
import { Box, Grid, Typography } from '@mui/material';
import SurveyChart, { MyChartData } from './survey-chart';
import { fetchSurveys } from '../../../store/slices/survey';


const SurveyDetails = () => {
  const { id } = useParams();
  const { error, status, surveyData } = useSelector((state) => state.survey);
  const [selectedSurveyData, setSelectedSurveyData] = useState<{user: SurveyUser, surveys: SurveyData[]} | undefined | null>(null);

  useEffect(() => {
    if (surveyData.length <= 0) {
      console.log(surveyData)
      dispatch(fetchSurveys());
    }
  }, []);

  useEffect(() => {
    if (surveyData) {
      setSelectedSurveyData(surveyData.find((data) => data.user.id === Number(id)));
    }
  }, [surveyData, id]);

  const getChartData = () => {
    if (selectedSurveyData) {
      const chartData: MyChartData[] = [];
      // get first question
      let index = 0;
      for (const question of selectedSurveyData?.surveys[0].questions) {
        //find questions answer in each survey
        const answers = selectedSurveyData?.surveys.map((survey) => JSON.parse(survey.answers as any)[index][0]);
        const dates = selectedSurveyData?.surveys.map((survey) => survey.createdAt);
        if (answers && question) {
          // find the index of each answer in availableAnswers
          const availableAnswers = JSON.parse(selectedSurveyData?.surveys[0].availableAnswers as any);
          const answerIndexes = answers.map((answer) => availableAnswers[index].indexOf(answer));
          chartData.push({
            question: question.toString(),
            indexs: answerIndexes,
            answers: availableAnswers[index],
            dates
          })
        } else {
          console.log(selectedSurveyData?.surveys.map((survey) => JSON.parse(survey.answers as any)[0]));
        }
        index ++;
      }
      //iterate mapData

      return chartData;
    }
    return null;
  }

  return (
    <MainCard title={`Survey Data: ${selectedSurveyData?.user.name}`} loading={status === ApiStatus.pending} error={error}>
      <Grid container>
        {
          getChartData()?.map((data, index) => (
            <Grid item xs={12} key={index} margin={2}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant='h3'>{data.question}</Typography>
                  <ul>{ data.answers.map((e) => (<li>{e}</li>)) }</ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ height: '200px' }}>
                    <SurveyChart surveyData={data} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </MainCard>
  )
}

export default SurveyDetails
