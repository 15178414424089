/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { parseISO } from 'date-fns';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === 'string' && isoDateFormat.test(value);
}

// eslint-disable-next-line consistent-return
export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
            body[key] = parseISO(value);
        } else if (typeof value === 'object') {
            handleDates(value);
        }
    }
}

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_HOST
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        handleDates(response.data);
        return response;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
