import { Draggable } from "react-beautiful-dnd";
import { ToolkitContent } from "../../../../types/toolkit";
import { Button, Checkbox, TableCell, TableRow } from "@mui/material";
import DeleteModal from "../../common/delete-modal";
import { dispatch, useSelector } from "../../../../store";
import { changeLiveStatus } from "../../../../store/slices/toolkit";
import { ApiStatus } from "../../../../types";

interface DraggableRowProps {
    content: ToolkitContent;
    index: number;
    getLink: (url: string) => JSX.Element;
    editContent: (content: ToolkitContent) => void;
    deleteContent: (content: ToolkitContent) => void;
  }
  export default function DraggableTableRow({
    content,
    index,
    getLink,
    editContent,
    deleteContent
  }: DraggableRowProps) {
    const status = useSelector((state) => state.toolkit.status);

    const handleLiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(changeLiveStatus({id: content.id, live: event.target.checked}));
    }
    return (
      <Draggable key={content.id} draggableId={`content-${index}`} index={index}>
        {(provided) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            hover
            key={content.id}
          >
            <TableCell align="center" style={{ verticalAlign: "center" }}>
              {index + 1}
            </TableCell>
            <TableCell align="center" style={{ verticalAlign: "center" }}>
              { (status !== ApiStatus.pending) && <Checkbox key={content.id} id={`${content.id}`} checked={content.isLive} onChange={handleLiveChange} />}
            </TableCell>
            <TableCell style={{ verticalAlign: "center" }}>
              {content.title}
            </TableCell>
            <TableCell style={{ verticalAlign: "center" }}>
              {content.description}
            </TableCell>
            <TableCell align="center" style={{ verticalAlign: "center" }}>
              {content.views}
            </TableCell>
            <TableCell align="center" style={{ verticalAlign: "center" }}>
              {content.likes}
            </TableCell>
            <TableCell align="center">
              {content.links.length > 0 ? getLink(content.links[0]) : ""}
            </TableCell>
            <TableCell align="center" style={{ verticalAlign: "center" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    editContent(content);
                  }}
                >
                  Edit
                </Button>
                <DeleteModal onDelete={()=>{deleteContent(content)}}/>
              </div>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    );
  }
