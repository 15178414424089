// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps, User } from '../../types';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['reports'] = {
    error: null,
    status: ApiStatus.idle,
    categoryReports: [],
    subCategoryReports: [],
    contentReports: []
};

export const fetchCategoryReport = createAsyncThunk('/users/category_report', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/users/category_report`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const fetchSubCategoryReport = createAsyncThunk('/users/subcategory_report', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/users/subcategory_report`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const fetchContentsReport = createAsyncThunk('/users/content_report', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/users/content_report`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.categoryReports = initialState.categoryReports;
            state.subCategoryReports = initialState.subCategoryReports;
            state.contentReports = initialState.contentReports;
        }
    },
    extraReducers(builder) {
        // fetchContentsReport
        builder.addCase(fetchCategoryReport.fulfilled, (state, action) => ({
            contentReports: state.contentReports,
            categoryReports: action.payload,
            subCategoryReports: state.subCategoryReports,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchCategoryReport.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchCategoryReport.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // fetchSubCategoryReport
        builder.addCase(fetchSubCategoryReport.fulfilled, (state, action) => ({
            contentReports: state.contentReports,
            categoryReports: state.categoryReports,
            subCategoryReports: action.payload,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchSubCategoryReport.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchSubCategoryReport.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        //fetchContentsReport
        builder.addCase(fetchContentsReport.fulfilled, (state, action) => ({
            contentReports: action.payload,
            categoryReports: state.categoryReports,
            subCategoryReports: state.subCategoryReports,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchContentsReport.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchContentsReport.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
