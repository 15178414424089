import { DailyQuestion } from "./daily-question";
import { Help } from "./help";
import { Mantra } from "./mantra";
import { Mood } from "./mood";
import { SurveyData } from "./survey";
import { ToolkitGroup } from "./toolkit";
import { SurveyUser, User } from "./user";

export enum ApiStatus {
    idle = 'idle',
    pending = 'pending',
    success = 'success',
    failure = 'failue'
}

export interface AsyncProps {
    status: ApiStatus;
    error: object | string | null;
}

export interface DailyQuestionsProps extends AsyncProps {
    questions: Array<DailyQuestion>;
}

export interface MoodsProps extends AsyncProps {
    moods: Array<Mood>;
    detailedMood: Mood | null;
}

export interface MantrasProps extends AsyncProps {
    mantras: Array<Mantra>;
}

export interface UsersProps extends AsyncProps {
    users: Array<User>
}

export interface ToolkitProps extends AsyncProps {
    groups: Array<ToolkitGroup>
}

export interface HelpProps extends AsyncProps {
    help: Array<Help>
}

export interface ReportsProps extends AsyncProps {
    categoryReports: Array<any>;
    subCategoryReports: Array<any>;
    contentReports: Array<any>;
}

export interface SurveyProps extends AsyncProps {
    surveyData: Array<{user: SurveyUser, surveys: SurveyData[]}>;
}

export interface DefaultRootStateProps {
    dailyQuestions: DailyQuestionsProps,
    moods: MoodsProps,
    mantras: MantrasProps,
    users: UsersProps,
    toolkit: ToolkitProps,
    help: HelpProps
    reports: ReportsProps,
    survey: SurveyProps,
};
