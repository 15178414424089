// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, DefaultRootStateProps } from '../../types';
import { Mood } from '../../types/mood';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['moods'] = {
    error: null,
    status: ApiStatus.idle,
    moods: [],
    detailedMood: null,
};

export const fetchMoods = createAsyncThunk('/moods', async (data, thunkAPI) => {
    try {
        const response = await axios.get(`/moods/admin`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const addMood = createAsyncThunk('/moods/add', async (data: any, thunkAPI) => {
    try {
        const response = await axios.post(`/moods/add`, data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const updateMood = createAsyncThunk('/moods/update', async (data: any, thunkAPI) => {
    try {
        const response = await axios.patch(`/moods/update/${data.id}`, data.payload);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getMoodById = createAsyncThunk('/moods/getById', async (data: any, thunkAPI) => {
    try {
        const response = await axios.get(`/moods/${data}`);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

const slice = createSlice({
    name: 'moods',
    initialState,
    reducers: {
        clear(state) {
            state.error = initialState.error;
            state.status = initialState.status;
            state.moods = initialState.moods;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMoods.fulfilled, (state, action) => ({
            moods: action.payload.sort((a: Mood, b: Mood) => a.createdAt.getTime() - b.createdAt.getTime()),
            detailedMood: null,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(fetchMoods.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(fetchMoods.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        // Add daily question
        builder.addCase(addMood.fulfilled, (state, action) => ({
            moods: [ ...state.moods, action.payload],
            detailedMood: null,
            error: null,
            status: ApiStatus.success
        }));
        builder.addCase(addMood.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(addMood.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });

        // Update Mood
        builder.addCase(updateMood.fulfilled, (state, action) => {
            const idx = state.moods.findIndex((e) => { return (e.id === action.payload.id)});
            const temp: any = [...state.moods];
            temp.splice(idx, 1);
            return {
                moods: [ ...temp, action.payload].sort((a: Mood, b: Mood) => a.createdAt.getTime() - b.createdAt.getTime()),
                error: null,
                detailedMood: null,
                status: ApiStatus.success
            }
        });
        builder.addCase(updateMood.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(updateMood.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
        //getMoodById
        builder.addCase(getMoodById.fulfilled, (state, action) => ({
            moods: state.moods,
            error: null,
            detailedMood: action.payload,
            status: ApiStatus.success
        }));
        builder.addCase(getMoodById.rejected, (state, action) => {
            state.error = action.payload as string;
            state.status = ApiStatus.failure;
        });
        builder.addCase(getMoodById.pending, (state, action) => {
            state.status = ApiStatus.pending;
        });
    }
});

// Reducer
export default slice.reducer;
export const { clear } = slice.actions;
