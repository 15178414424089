// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { dispatch, useSelector } from "../../../../store";
import S3Utils from "../../../../utils/s3";
import { LoadingButton } from "@mui/lab";
import { ApiStatus } from "../../../../types";
import { useState } from "react";
import { ToolkitContent, ToolkitSubCategory } from "../../../../types/toolkit";
import { addContent, editContent } from "../../../../store/slices/toolkit";

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //
interface AddContentProps {
    isOpenContent: boolean;
    isEditing: boolean;
    handleClose: Function;
    subCategory: ToolkitSubCategory | null | undefined;
    content: ToolkitContent | null | undefined;
}

export default function AddContent({
    content,
    isOpenContent,
    isEditing,
    handleClose,
    subCategory,
}: AddContentProps) {
    const theme = useTheme();
    const { error, status } = useSelector((state) => state.toolkit);
    const ANIMATION_SUPPORTED_FORMATS = ["image/gif", "image/png", "video/mp4"];

    const [loading, setLoading] = useState<boolean>(false);
    const [title, setTitle] = useState(content?.title);
    const [description, setDescription] = useState(content?.description);
    // const [animationFile, setAnimationFile] = useState(content?.links[0]);
    // const [audioFile, setAudioFile] = useState(content?.links[1]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title ?? "",
            description: description ?? "",
            animationFile: undefined,
            links: content?.links ?? [],
        },
        validationSchema: isEditing ? Yup.object().shape({
            title: Yup.string().required("Message is required"),
            description: Yup.string().required("Related Text is required"),
        }) : Yup.object().shape({
            title: Yup.string().required("Message is required"),
            description: Yup.string().required("Related Text is required"),
        }),
        onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
            try {
                setLoading(true);
                // upload files
                var links = [];

                if (isEditing) {
                    if (values.animationFile) {
                        const animResult = await S3Utils.uploadFile(values.animationFile);
                        if (!animResult) {
                            setLoading(false);
                            setSubmitting(false);
                            setFieldError(
                                "animationFile",
                                "Error occured while uploading file"
                            );
                            return;
                        }
                        links.push(animResult);
                    } else {
                        links = [...content!.links];
                    }

                    await dispatch(editContent({
                        contentId: content!.id,
                        payload: {
                            subCategoryId: subCategory!.id,
                            title: values.title,
                            description: values.description,
                            links: links,
                        }
                    }));
                } else {
                    const links: string[] = [];
                    if (values.animationFile) {
                        const animResult = await S3Utils.uploadFile(values.animationFile);
                        if (!animResult) {
                            setLoading(false);
                            setSubmitting(false);
                            setFieldError(
                                "animationFile",
                                "Error occured while uploading file"
                            );
                            return;
                        }
                        links.push(animResult);
                    }

                    await dispatch(
                        addContent({
                            title: values.title,
                            description: values.description,
                            subCategoryId: subCategory!.id,
                            links: links,
                        })
                    );
                }
                setLoading(false);
                handleClose();
            } catch (error) {
                console.log(error);
            }
        },
    });
    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        isSubmitting,
    } = formik;

    return (
        <>
            <DialogTitle>{isEditing ? 'Edit Content' : 'Add Content'}</DialogTitle>
            {error && (
                <Alert variant="outlined" color="error">
                    error
                </Alert>
            )}
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent sx={{ width: 600 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    onInput={(e: any) => setTitle(e.target.value)}
                                    {...getFieldProps("title")}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="description"
                                    label="Description"
                                    onInput={(e: any) => setDescription(e.target.value)}
                                    multiline
                                    {...getFieldProps("description")}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Upload Video</Typography>
                                {errors.animationFile && touched.animationFile && (
                                    <Alert color="error">{errors.animationFile}</Alert>
                                )}
                                <input
                                    id="animationFile"
                                    name="animationFile"
                                    type="file"
                                    onChange={(event) => {
                                        setFieldValue(
                                            "animationFile",
                                            event.currentTarget.files?.[0]
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            onClick={() => {
                                handleClose();
                            }}
                            variant="outlined"
                            size="small"
                            sx={{
                                color: theme.palette.primary.dark,
                                borderColor: theme.palette.primary.dark,
                            }}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={status === ApiStatus.pending || loading}
                            disabled={isSubmitting}
                            type="submit"
                            variant="outlined"
                            size="small"
                            autoFocus
                        >
                            {isEditing ? 'Save' : 'Add Content'}
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </>
    );
}
