import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Avatar, Box, Button, Link, Typography, useTheme } from '@mui/material';
import { DASHBOARD_PATH } from '../../../config';
import LogoSection from '../logosection';
import { Dashboard } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';


const Header = () => {
    const theme = useTheme();
    const { logout, user } = useAuth();
    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => {}}
                    color="inherit"
                >
                    <Dashboard />
                </Avatar>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' }, mr: 2 }}>
                <Button
                    onClick={() => {
                        logout();
                    }}
                    variant="outlined"
                >
                    Logout {user?.name}
                </Button>
            </Box>
        </>
    )
};

export default Header;
