import { useRoutes } from 'react-router-dom';
import { authRoutes } from './main-routes';
import { unAuthRoutes } from './unauth-routes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        unAuthRoutes,
        authRoutes,
    ]);
}
