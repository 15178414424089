import * as React from 'react';

// material-ui
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Chip
} from '@mui/material';

import { useTheme, styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

// project imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import { fetchDailyQuestions } from "../../../store/slices/daily-questions";
import MainCard from "../../../ui-components/cards/maincard";
import AddDailyQuestion from "./components/add-dailyquestion";

// assets
import { ApiStatus } from '../../../types';
import { DailyQuestion } from '../../../types/daily-question';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight:'600'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 10
    },
  }));

  // table columns
  interface ColumnProps {
    id: string;
    label: string;
    minWidth: number;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
    format?: (value: Date | number) => string | boolean;
}

const columns: ColumnProps[] = [
    { id: 'index', label: 'Sr.No.', minWidth: 50, align: 'center' },
    { id: 'question', label: 'Question', minWidth: 100, align: 'left' },
    { id: 'answerCount', label: 'Answer Count', minWidth: 100, align: 'center' },
    { id: 'active', label: 'Active', minWidth: 100, align: 'center' },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' }
];

// ==============================|| TABLE - ENHANCED ||============================== //

export default function DailyQuestions() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dailyQuestion, setDailyQuestion] = useState<DailyQuestion | undefined>(undefined);

    const dispatch = useDispatch();
    const {error, status, questions } = useSelector((state) => state.dailyQuestions);

    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const theme = useTheme();
    
    useEffect(() => {
        if (questions.length <= 0) {
            dispatch(fetchDailyQuestions());
        }
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setPage(0);
    };

    const chipSX = {
        height: 20,
        padding: '6px 3px'
    };

    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    };
    const editQuestion = (dailyQuestion: DailyQuestion) => {
        setDailyQuestion(dailyQuestion);
        setIsOpen(true);
    }
    return (
        <>
            <Box sx={{ margin: 2 }}>
                <MainCard
                    title="Daily Questions"
                    loading={status === ApiStatus.pending}
                    error={error}
                    secondary={
                        <Button variant="contained" onClick={() => {setIsOpen(true)}}>Add Daily Question</Button>
                    }
                >
                    {/* table */}
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            sx={{ py: 3 }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, verticalAlign: 'top' }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                        >
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>
                                                {index+1}
                                            </TableCell>
                                            <TableCell id="question-id" style={{ verticalAlign: 'top' }}>
                                                {row.question}
                                            </TableCell>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>{row.answersCount}</TableCell>
                                            <TableCell align="center" style={{ verticalAlign: 'top' }}>
                                            {row.isActive === true ? (
                                                <Chip size="small" label="Yes" sx={chipSuccessSX} />
                                                ) : (
                                                <Chip size="small" label="No" sx={chipErrorSX} />
                                            )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button onClick={()=>{editQuestion(row)}}>Edit</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* table pagination */}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={Number(questions?.length)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </Box>
        <AddDailyQuestion dailyQuestion={dailyQuestion} isOpen={isOpen} handleClose={()=>{setIsOpen(false); setDailyQuestion(undefined)}} />
        </>
    );
}
