import * as React from 'react';

// material-ui
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Typography,
    Link
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

// project imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../store";
import MainCard from "../../../ui-components/cards/maincard";

// assets
import { ApiStatus } from '../../../types';
import { fetchMantras } from '../../../store/slices/mantras';
import AddMantra from './components/add-mantra';
import { Mantra } from '../../../types/mantra';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight:'600',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 10
    },
  }));

// table columns
interface ColumnProps {
    id: string;
    label: string;
    minWidth: number;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined;
    format?: (value: Date | number) => string | boolean;
}

const columns: ColumnProps[] = [
    { id: 'id', label: 'Sr.No.', minWidth: 50, align: 'center' },
    { id: 'message', label: 'Message', minWidth: 100, align: 'left' },
    { id: 'relatedText', label: 'Related Text', minWidth: 100, align: 'left' },
    { id: 'animation', label: 'Animation', minWidth: 100, align: 'center' },
    { id: 'music', label: 'Music', minWidth: 100, align: 'center' },
    { id: 'views', label: 'Views', minWidth: 100, align: 'center' },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' }
];

// ==============================|| TABLE - ENHANCED ||============================== //

export default function Mantras() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch();
    const { error, mantras, status } = useSelector((store) => store.mantras);
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    const [mantra, setMantra] = useState<Mantra | undefined>(undefined);

    useEffect(() => {
        if (mantras.length <= 0) {
            dispatch(fetchMantras());
        }
    }, []);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setPage(0);
    };

    const editMantra = (mantra: Mantra) => {
        setMantra(mantra);
        setIsOpen(true);
    }
    return (
        <>
            <Box sx={{ margin: 2 }}>
                <MainCard
                    title="Mantras"
                    loading={status === ApiStatus.pending}
                    error={error}
                    secondary={
                        <Button variant="contained" onClick={() => {setIsOpen(true)}}>Add Mantra</Button>
                    }
                >
                    {/* table */}
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            sx={{ py: 3 }}
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mantras.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                        >
                                            <TableCell align="center">
                                                {index+1}
                                            </TableCell>
                                            <TableCell id="message-id" align="left">
                                                {row.message}
                                            </TableCell>
                                            <TableCell align="left">{row.relatedText}</TableCell>
                                            <TableCell align="center">
                                                <Link href={row.animatedGif} target="_blank">
                                                    <Typography>Link</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link href={row.mp3Audio} target="_blank">
                                                    <Typography>Link</Typography>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.views}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button onClick={()=>{editMantra(row)}}>Edit</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* table pagination */}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={Number(mantras.length)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </Box>
        <AddMantra mantra={mantra} isOpen={isOpen} handleClose={()=>{setIsOpen(false); setMantra(undefined)}} />
        </>
    );
}
