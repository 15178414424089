import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Typography } from '@mui/material';
import { DASHBOARD_PATH } from '../../../config';


const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <Typography>Minted Content</Typography>
    </Link>
);

export default LogoSection;
