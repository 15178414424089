import React from 'react'
import { AxisOptions, Chart } from 'react-charts'

type MySeries = { label: string, data: any }

export type MyChartData = {
  question: string;
  answers: string[];
  indexs: number[];
  dates: Date[];
}

const SurveyChart = ({ surveyData }: { surveyData: MyChartData }) => {
  const series = React.useMemo<MySeries[]>(
    () => [
      {
        label: surveyData.question,
        data: surveyData.indexs.map((datum, index) => ({
          primary: surveyData.dates[index],
          secondary: surveyData.indexs[index],
        })),
      },
    ],
    []
  );

  console.log({series})
  console.log({surveyData})
  const primaryAxis = React.useMemo<
    AxisOptions<typeof series[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary as unknown as Date,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof series[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        formatters: {
          tooltip: (datum: any) => <div>{surveyData.answers[datum]}</div>,
          scale: (datum: any) => Number.isInteger(datum) ? surveyData.answers[datum] : ""
        }
      },
    ],
    []
  );

  return (
    <>
        <Chart
          options={{
            data: series,
            primaryAxis,
            secondaryAxes,
          }}
        />
    </>
  );
}

export default SurveyChart
