import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Theme, styled } from '@mui/material/styles';
import { dispatch, useSelector } from "../../../../store";

import MainCard from "../../../../ui-components/cards/maincard";
import { ApiStatus } from "../../../../types";
import { fetchMantras } from "../../../../store/slices/mantras";


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',

    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
      borderTop: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  }));

  const columns: GridColDef[] = [
    { field: 'id', type: 'number', renderHeader: () => (<strong>{'Sr.No.'}</strong>), renderCell: (index) => index.api.getRowIndex(index.row.id) + 1, width: 80, },
    { field: 'message', headerName: 'Mantra', type: 'string', flex: 1 },
    { field: 'views', headerName: 'Views', type: 'string', flex: 1 },
  ];

const MantrasReport = () => {
  const { mantras, status } = useSelector((state) => state.mantras);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
      if (mantras.length <= 0) {
          dispatch(fetchMantras());
      }
  }, [mantras]);

  return (
      <Box sx={{ margin: 2 }}>
          <MainCard loading={status === ApiStatus.pending} title="Mantra Views">
              <Box sx={{ height: 800, width: '100%', backgroundColor: 'none' }}>
                  <StyledDataGrid
                      aria-label="Mantra Views"
                      rows={mantras}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      disableSelectionOnClick
                      components={{ Toolbar: GridToolbar }}
                  />
              </Box>
          </MainCard>
      </Box>
  )

}
export default MantrasReport;
