import React, { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { dispatch, useSelector } from '../../../../store';
import { addMood, updateMood } from '../../../../store/slices/moods';
import { LoadingButton } from '@mui/lab';
import { ApiStatus } from '../../../../types';
import { useState } from 'react';
import { Mood } from '../../../../types/mood';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //
interface AddMoodProps {
    isOpen: boolean;
    handleClose: Function;
    mood?: Mood;
}

export default function AddMood({ isOpen, handleClose, mood }: AddMoodProps) {
    const theme = useTheme();
    const { moods, error, status } = useSelector((state) => state.moods);
    const [loading, setLoading] = useState<boolean>(false);
    
    useEffect(() => {
    }, [moods]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            mood: mood?.mood ?? '',
            message: mood?.message ?? '',
            text: mood?.text ?? '',
            rating: mood?.rating ?? 1,
        },

        validationSchema: mood ? Yup.object().shape({
            mood: Yup.string().required('Mood text is required field.'),
            message: Yup.string().required('Message text is required field.'),
            text: Yup.string().required('Text field cannot be empty.'),
            rating: Yup.number().required('Rating is a required field.')
        }) : Yup.object().shape({
            mood: Yup.string().required('Mood text is required field.'),
            message: Yup.string().required('Message text is required field.'),
            text: Yup.string().required('Text field cannot be empty.'),
            rating: Yup.number().required('Rating is a required field.')
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (mood) {
                    setLoading(true);
                    if (!values.mood) {
                        setLoading(false);
                        setSubmitting(false);
                        return;
                    }
                    if (!values.message) {
                        setLoading(false);
                        setSubmitting(false);
                        return;
                    }
                    if (!values.text) {
                        setLoading(false);
                        setSubmitting(false);
                        return;
                    }
                    await dispatch(updateMood({
                        id: mood?.id,
                        payload: {
                            mood: values.mood,
                            message: values.message,
                            text: values.text,
                            rating: parseInt(values.rating.toString())
                        }
                    }));
                    setLoading(false);
                } else {
                    await dispatch(addMood({
                        mood: values.mood,
                        message: values.message,
                        text: values.text,
                        rating: parseInt(values.rating.toString())
                    }));
                }
                handleClose();
            } catch (error) {
                console.log(error);
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                handleClose();
            }}
            sx={{ p: 3 }}
        >
            <>
                <DialogTitle>{mood ? 'Update' : 'Add'} Mood</DialogTitle>
                {error && (
                    <Alert variant="outlined" color="error">
                        error
                    </Alert>
                )}
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogContent sx={{width: 600}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Mood"
                                        multiline
                                        {...getFieldProps('mood')}
                                        error={Boolean(touched.mood && errors.mood)}
                                        helperText={touched.mood && errors.mood}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        multiline
                                        {...getFieldProps('message')}
                                        error={Boolean(touched.message && errors.message)}
                                        helperText={touched.message && errors.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Text"
                                        multiline
                                        {...getFieldProps('text')}
                                        error={Boolean(touched.text && errors.text)}
                                        helperText={touched.text && errors.text}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel id="select-rating">Rating</InputLabel>
                                    <Select 
                                        labelId="select-rating"
                                        id="rating"
                                        {...getFieldProps('rating')}
                                    >
                                        <MenuItem  value={1}>1</MenuItem >
                                        <MenuItem  value={2}>2</MenuItem >
                                        <MenuItem  value={3}>3</MenuItem >
                                        <MenuItem  value={4}>4</MenuItem >
                                        <MenuItem  value={5}>5</MenuItem >
                                        <MenuItem  value={6}>6</MenuItem >
                                        <MenuItem  value={7}>7</MenuItem >
                                        <MenuItem  value={8}>8</MenuItem >
                                        <MenuItem  value={9}>9</MenuItem >
                                        <MenuItem  value={10}>10</MenuItem >
                                    </Select>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                onClick={() => {
                                    handleClose();
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <LoadingButton loading={status === ApiStatus.pending || loading} disabled={isSubmitting} type="submit" variant="outlined" size="small" autoFocus>
                                {mood ? 'Save' : 'Create'}
                            </LoadingButton>
                        </DialogActions>
                    </Form>  
                </FormikProvider>
            </>
        </Dialog>
    );
}
